import React, {useEffect, useState} from 'react';
import { useAuth } from '../../context/auth.context';
import estudianteService from '../../services/estudiante.service';
import {Row,Col, Table, Button, Modal, Spin} from 'antd'
import CursoEstudiante from '../../components/CursoEstudiante';
import MyLayout from '../../components/MyLayout';
import moment from 'moment';
import evaluacion_estudianteService from '../../services/evaluacion_estudiante.service';
import inasistenciaService from "../../services/inasistencia.service"
import './CursosEstudiante.css'
const CursosEstudiante = () => {

    const [cursosMatriculados,setCursosMatriculados] = useState([]);
    const [cursoSelected,setCursoSelected] = useState()
    const [estudiante,setEstudiante] = useState(null);
    const [loading, setLoading] = useState(false);
    const [viewModalNotas,setViewModalNotas] = useState(false)
    const [notas,setNotas] = useState([])
    const [inasistencias,setInasistencias] = useState([]);
    const {user} = useAuth()

    useEffect(()=>{
        getEstudiante()
    },[])

   const getInasistencias = (curso_id,estudiante_id) =>{
    inasistenciaService.getForain(curso_id).then(res=>{
     
      let filtro = res.data.filter(item=>item.estudiante_id == estudiante_id && item.asiste == "No asiste")
      setInasistencias(filtro)
     
    })
   }

    

    const getEstudiante = () => {
        let correo = user.correo
        setLoading(true);
        estudianteService
          .getPerfil({correo})
          .then((response) => {
            let filterEstudiante = response.data[0];
        
            //setFotoPerfil(filterEstudiante.url)
            setEstudiante(filterEstudiante);
            let cursos = [];
              let entrenamientos=[];
            if(filterEstudiante.Matriculas.length>0){
             
              for(let item of filterEstudiante.Matriculas){
              
                  cursos.push(item)
                
              }
            }
            setCursosMatriculados(cursos)
           
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      const verNotas = (record) =>{
       
        setCursoSelected(record)
        getInasistencias(record.curso_id,record.estudiante_id)
        setLoading(true)
        setNotas([])
        evaluacion_estudianteService.getForain(record.curso_id).then(res=>{
            setViewModalNotas(true)
            let notasEstudiante = res.data.filter(item=>item.estudiante_id == record.estudiante_id)
           

            let cursos = []
            let cursosAsignatura = []

            for(let curso of notasEstudiante){
                if(cursos.filter(item=>item.curso == curso.curso_id).length == 0){
                    let matricula = notasEstudiante.filter(item=>item.curso_id == curso.curso_id)
                    cursos.push({'curso':curso.curso_id, "infoCurso":curso.Curso, "matricula":matricula})
                }
            }

            for(let c of cursos){
                let totalNotaCurso = 0
                let dataFilter = notasEstudiante.filter(item=>item.curso_id == c.curso)
             
                let cursosparams = []
                let cursosSubparam = []
                for(let dataF of dataFilter){
                    if(cursosparams.filter(item=>item.parametroP == dataF.parametro_id).length == 0){
                      cursosparams.push({"parametroP":dataF.parametro_id,"porcentajeP":dataF.ParametroCurso.porcentaje,"nombreP":dataF.ParametroCurso.nombre})
                  }
                }

                for(let para of cursosparams){
                    let totalNotasSub = 0
                    let totalNotasPadre = 0
                   let datasub= dataFilter.filter(item=>item.parametro_id == para.parametroP)
                   for(let notasSub of datasub){
                    totalNotasSub += notasSub.valor * (notasSub.SubparametroCurso.porcentaje/100)
                   }
                   totalNotasPadre = totalNotasSub*(para.porcentajeP/100)
                   cursosSubparam.push({"padre":para,"hijo":datasub,"notasHijoResumen":totalNotasSub,"notaPadre":totalNotasPadre})
          
                  }

                  for(let nPadre of cursosSubparam){
                    totalNotaCurso += nPadre.notaPadre
                  }
          
                  let estado;
          
                  totalNotaCurso < 3 ? estado="No aprobada" : estado="Aprobada"
          
                cursosAsignatura.push({"curso":c,"totalGeneral":totalNotaCurso,"data":cursosSubparam,"estado":estado})
               
            }

           
            setNotas(cursosAsignatura)
            setLoading(false)
        })
      }

      const columns = [
        {
        title: "Codigo Curso",
        render:(record)=><div>{record.Curso.id}</div>
      },
      
      {
        title: "Curso/Entrenamiento",
        render:(record)=><div>{record.Curso.modo} {record.Curso.curso} - {record.Curso.nivel} - {record.Curso.ciclo}</div>
      },
      {
        title: "Fecha Inicio",
        render:(record)=><div>{moment(record.Curso.fechaInicial).format("DD-MM-YYYY")}</div>
      },

      {
        title: "Fecha Final",
        render:(record)=><div>{moment(record.Curso.fechaFinal).format("DD-MM-YYYY")}</div>
      },

      {
        title: "Periodo",
        render:(record)=><div>{record.Curso.periodo}</div>
      },

      {
        title: "Estado",
        render:(record)=><div>{record.Curso.estado}</div>
      },

      {
        title: "Opciones",
        render:(record)=><div><Button type="primary" onClick={()=>verNotas(record)}>Ver Notas</Button></div>
      },


    ]

    if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    );
    return (
        <MyLayout>
            {cursosMatriculados.length==0 && (
                       <Col span={24}>
                       <div className='messageNo'> No tiene cursos Matriculados </div> 
                      
                       </Col>
                       )}

                  {cursosMatriculados.length>0 && (
                       <Table dataSource={cursosMatriculados} columns={columns} scroll={{ x: '100%'}}></Table>
                       )} 


                       {viewModalNotas && (
                        <Modal title="Resultados Académicos"  visible={viewModalNotas} width={1080} onCancel={()=>setViewModalNotas(false)}  cancelText="Cancelar" okText="Cerrar" onOk={()=>setViewModalNotas(false)}>
{notas.map((item)=>{
    return <>
    <div class="table-responsive">
                <table className='tabla_notas'   >

                <tr>
                        <th ><strong>Curso </strong> </th>
                        <th><strong>Fecha Inicio/Fecha Fin</strong>  </th>
                        <th><strong>Jornada </strong> </th>
                        <th><strong>Periodo </strong> </th>
                        <th><strong>Nota Final </strong> </th>
                        <th><strong>Estado</strong> </th>
                    </tr>
                    
                         <tr>
                        <td width={400}> {cursoSelected.Curso.curso} - {cursoSelected.Curso.nivel} - {cursoSelected.Curso.ciclo}</td>
                        <td style={{textAlign:'center'}}> {moment(cursoSelected.Curso.fechaInicial).format("DD-MM-YYYY")} / {moment(cursoSelected.Curso.fechaFinal).format("DD-MM-YYYY")} </td>
                        <td style={{textAlign:'center'}}> {cursoSelected.Curso.jornada}</td>
                        <td style={{textAlign:'center'}}> {cursoSelected.Curso.periodo}</td>
                        <td style={{fontSize:"16px",textAlign:'center'}}>{item.totalGeneral.toFixed(1)} </td>
                        <td> {item.estado} </td>
                    </tr>

                    {item.data.map((corte)=>{
                         return <> <tr> 
                        
                          <td > {corte.padre.nombreP} - {corte.padre.porcentajeP}%</td>

                          {corte.hijo.map((hijo)=>{
                            return   <td style={{textAlign:'center'}}> <strong> {hijo.SubparametroCurso.nombre}</strong></td>
                             })}
                          {/* <td >{corte.padre.porcentajeP}%</td> */}
                          {/* <td >{corte.notasHijoResumen.toFixed(1)}</td> */}
                    </tr>
                        <tr>
                            <td></td>
                        {corte.hijo.map((hijo)=>{
                            return   <td style={{textAlign:'center'}}> {hijo.valor}</td>
                             })}
                        </tr>
                        <tr>
                            <td><strong>PROMEDIO</strong></td>
                            <td colSpan={4} style={{textAlign:'center'}}>{corte.notasHijoResumen.toFixed(1)}</td>
                        </tr>


                    </>
                    })}
                   
                   
                   <tr> 
                            {cursoSelected  && (
                                <td colSpan={6} className="observaciones"><strong>OBSERVACIONES: </strong>{cursoSelected.observaciones}</td>
                            )}
                        </tr>

                        <tr> 
                            {inasistencias  && inasistencias.length>0 && (
                                <td colSpan={6} className="observaciones"><strong>TOTAL INASISTENCIAS: {inasistencias.length}</strong>
                               {inasistencias.map((item)=>{
                                return <div><strong>{moment(item.fecha).format("DD-MM-YYYY")}:</strong> <span>{item.detalle}</span></div>
                               })}
                                </td>
                            )}
                        </tr>
                   

                </table>

                </div>


                <br />
                <br />
                </>
            })}
                        </Modal>
                       )}
        </MyLayout>
    );
};

export default CursosEstudiante;