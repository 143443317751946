import React, {useEffect, useState} from "react"
import {Link, useParams, useLocation, useNavigate} from "react-router-dom"
import {Button, Modal, Form, Select, Input, Table, message} from "antd"
import "./Item.css"
import {Spin} from "antd"
import categoriaBV from "../../services/categoriabv.service"
import {UploadOutlined, CloudUploadOutlined} from "@ant-design/icons"
import storage from "../../services/firebase"
import {ref, uploadBytesResumable, getDownloadURL} from "firebase/storage"
import archivoBVService from "../../services/archivobv.service"
import {useAuth} from "../../context/auth.context"
const Hijo = () => {
  const {user, role, saveRole, saveUser} = useAuth()
  let location = useLocation()
  let myParent = location.state.parent

  const params = useParams()

  const [formNewFile] = Form.useForm()
  const itemId = parseInt(params.id)
  const {TextArea} = Input
  const [file, setFile] = useState()

  const [data, setData] = useState()
  const [children, setChildren] = useState()
  const [loading, setLoading] = useState()
  const [viewUpload, setViewUpload] = useState(false)
  const [verUpload, setVerUpload] = useState(false)
  const [archivos, setArchivos] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    console.log("SE ACTUALIZA")
    loadCategorias()
    loadArchivos()
  }, [itemId])

  const loadCategorias = () => {
    setLoading(true)
    categoriaBV.getAll().then((res) => {
      setData(res.data.filter((item) => item.padre_id === itemId))

      setLoading(false)
    })
  }

  const loadArchivos = () => {
    setLoading(true)
    archivoBVService.getForain(myParent.id).then((res) => {
      let filtro = res.data.filter((item) => item.estado == "Activo")
      setArchivos(filtro)

      setLoading(false)
    })
  }

  const validarTipo = (tipo) => {
    if (tipo == "LINK") {
      setVerUpload(false)
    } else {
      setVerUpload(true)
    }
  }

  const verCarga = () => {
    setViewUpload(true)
    formNewFile.resetFields()
  }

  const subirArchivo = (values) => {
    console.log(values)

    let formEnviar = values
    formEnviar.categoria_id = myParent.id
    formEnviar.docente_id = user.id

    if (file) {
      const storageRef = ref(
        storage,
        `/biblioteca/${myParent.id}/${new Date().getTime() + "-" + file.name}`
      )
      const uploadTask = uploadBytesResumable(storageRef, file)
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log(url)

            formEnviar.url = url

            archivoBVService
              .create(formEnviar)
              .then((response) => {
                console.log(response.data)
                setLoading(false)
                setViewUpload(false)
                setFile(null)
                loadArchivos()
                message.success("Guardado Correctamente")
              })
              .catch((e) => {
                setLoading(false)

                message.error("No se ha podido guardar")
                console.log(e)
              })
          })
        }
      )
    } else {
      archivoBVService
        .create(formEnviar)
        .then((response) => {
          console.log(response.data)
          setLoading(false)
          setViewUpload(false)
          setFile(null)
          loadArchivos()
          message.success("Guardado Correctamente")
        })
        .catch((e) => {
          setLoading(false)

          message.error("No se ha podido guardar")
          console.log(e)
        })
    }
  }

  const selectFile = (e) => {
    const file = e.target.files[0]

    const fileType = file.type
    setFile(file)
  }

  const descargar = (url) => {
    window.open(url)
  }

  const irHome = () => {
    navigate("/")
  }

  const columns = [
    {
      title: "Recurso",

      render: (record) => <div>{record.nombre}</div>,
    },

    {
      title: "Tipo",
      render: (record) => <div>{record.tipo}</div>,
    },

    {
      title: "Ver archivo",
      render: (record) => {
        if (record.tipo == "LINK") {
          return (
            <div>
              <a href={record.url} target="_blank">
                Visitar Link
              </a>
            </div>
          )
        } else {
          return (
            <div>
              <Button
                onClick={() => {
                  descargar(record.url)
                }}
              >
                Descargar{" "}
              </Button>
            </div>
          )
        }
      },
    },

    {
      title: "Información",
      render: (record) => <div>{record.info}</div>,
    },
  ]

  if (loading)
    return (
      <div className="spin_container">
        <Spin className="spin" size="large" />
      </div>
    )
  return (
    <div>
      <div className="barra-nav">
        <img
          onClick={irHome}
          src="./assets/img/logo.png"
          width={"50px"}
          style={{background: "white", borderRadius: "50%", cursor: "pointer"}}
        ></img>

        <div>{myParent.nombre}</div>
        {myParent.carga == "Si" && (
          <div className="upload">
            <Button onClick={verCarga}>
              <CloudUploadOutlined></CloudUploadOutlined>
            </Button>
          </div>
        )}
      </div>

      {data && (
        <div className="item-container">
          {data.map((child) => {
            return (
              <>
                <Link to={`/biblioteca/${child.id}`} state={{parent: child}}>
                  <div key={child.id} className="item">
                    {child.sigla}
                  </div>
                </Link>
              </>
            )
          })}

          {viewUpload && (
            <Modal
              visible={viewUpload}
              title="Cargar Archivo"
              cancelText="Cancelar"
              onCancel={() => {
                setViewUpload(false)
              }}
              okText="Guardar"
              onOk={formNewFile.submit}
            >
              <Form
                form={formNewFile}
                name="basic"
                layout="vertical"
                onFinish={subirArchivo}
              >
                <Form.Item
                  name="nombre"
                  label="Nombre Recurso"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese nombre",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="tipo"
                  label="Tipo de Archivo"
                  rules={[
                    {
                      required: false,
                      message: "Por favor ingrese Tipo de Archivo",
                    },
                  ]}
                >
                  <Select onChange={validarTipo}>
                    <Select.Option value="AUDIO">AUDIO</Select.Option>
                    <Select.Option value="VIDEO">VIDEO</Select.Option>
                    <Select.Option value="LINK">LINK</Select.Option>
                    <Select.Option value="OTRO">OTRO</Select.Option>
                  </Select>
                </Form.Item>

                {verUpload && (
                  <Form.Item
                    label="Cargar Archivo: "
                    name="file"
                    rules={[
                      {
                        required: true,
                        message: "Por favor Seleccione el archivo",
                      },
                    ]}
                  >
                    <Input
                      type="file"
                      onChange={(e) => {
                        selectFile(e)
                      }}
                    ></Input>
                  </Form.Item>
                )}

                {!verUpload && (
                  <Form.Item
                    label="Link del recurso: "
                    name="url"
                    rules={[
                      {
                        required: true,
                        message: "Por favor Ingrese Link",
                      },
                    ]}
                  >
                    <Input></Input>
                  </Form.Item>
                )}

                <Form.Item
                  name="clasificacion"
                  label="Clasificación del archivo"
                  rules={[
                    {
                      required: false,
                      message: "Por favor ingrese Clasificación del archivo",
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value="NIÑOS">NIÑOS</Select.Option>
                    <Select.Option value="JOVENES">JOVENES</Select.Option>
                    <Select.Option value="ADULTOS">ADULTOS</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="info"
                  label="Información del recurso"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese información del recurso",
                    },
                  ]}
                >
                  <TextArea></TextArea>
                </Form.Item>
              </Form>
            </Modal>
          )}
        </div>
      )}
      {myParent.carga == "Si" && (
        <div style={{padding: "50px"}}>
          <Table dataSource={archivos} columns={columns}></Table>
        </div>
      )}
    </div>
  )
}

export default Hijo
